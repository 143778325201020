import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { Chart } from '../domain/chart'
import { ChordResult } from '../components/ChordResult'
import { Link } from 'gatsby'
import IndexLayout from '../layouts'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import DownloadButtons from '../components/DownloadButtons'
import ShareButtons from '../components/ShareButtons'
import Helmet from 'react-helmet'
import { decompress } from '../hooks/compressed-state'
import { Referrals } from '../components/referrals/Referrals'

export default (props: { '*': string; location: Location }) => {
  const [size, setSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 })
  const [chart, setChart] = useState<Chart | null | undefined>(undefined)

  useEffect(() => {
    const loadedChart = decompress<Chart>(props['*'])
    setChart(loadedChart)
  }, [])

  const chartRef = useRef(null)

  return (
    <IndexLayout location={props.location}>
      {chart ? (
        <>
          <Helmet title={`Генератор гитарных диаграмм SAMESOUND ${chart.settings.title ? ` | ${chart.settings.title}` : ''}`} />
          <Row>
            <Col sm={6}>
              <ChordResult chart={chart} chartRef={chartRef} onSize={setSize} />
            </Col>
            <Col sm={{ span: 5, offset: 1 }} className="pt-5">
              <DownloadButtons chartRef={chartRef} size={size} />
              <ShareButtons chart={chart} />

              <h2>Создать диаграмму</h2>
              <Link to="/">Создать новую диаграмму</Link>
            </Col>
          </Row>
        </>
      ) : chart === null ? (
        <>
          <h2>Некорректная ссылка</h2>
          <p>К сожалению, указанная ссылка неверна. Проверьте правильность введённых данных.</p>

          <p>
		    Если у вас нет другой ссылки, то всё, что вам остаётся — это <Link to="/">вернуться на главную и создать новую диаграмму</Link>.
          </p>
        </>
      ) : (
        <p>Загрузка аккорда...</p>
      )}
      <Referrals />
    </IndexLayout>
  )
}
